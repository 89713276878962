export const colors = {
  primary: {
    main: '#2678FF',
    dark: '#1750AF',
    veryLight: '#2678FF05',
    disabled: '#A2B9DF',
    opacity: '#ffffff80',
    darker: '#001436',
  },
  green: {
    main: '#009506',
  },
  red: {
    main: '#AD0000',
  },
  grey: {
    500: '#8F8F8F',
    400: '#F2F2F2',
    300: '#DDDDDD',
    600: '#F6F6F6',
    700: '#757575',
    800: '#808080',
    900: '#A5A5A5',
    100: '#6D6D6D',
    200: '#d5d5d5',
    placeholder: '#d5d5d5',
    opacity: '#33333320',
  },
  black: {
    dark: '#000000',
    main: '#151515',
    alt: '#333333',
    opacity: '#00000060',
  },
  white: {
    main: '#FFFFFF',
  },
}
