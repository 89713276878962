import './App.css'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from './utils/theme/index'
import JustVideo from './pages/JustVideo/JustVideo'

/* eslint-disable import/first */
//test deploy test
const WaitingListRoot = React.lazy(() =>
  import('./pages/questions/WaitingListRoot'),
)
const Root = React.lazy(() => import('./Root'))

function App() {
  const isPreRelease = process.env.REACT_APP_IS_PRE_RELEASE
  // const isPreRelease = true
  return (
    <ThemeProvider theme={theme}>
      <JustVideo />
    </ThemeProvider>
  )
}

export default App
