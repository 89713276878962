import { Container } from '@mui/system';
import React from 'react';
import styled from 'styled-components';

const MainContainer = styled(Container)`
  @media (min-width: 1300px) {
    max-width: 76vw !important;
  }
`;
export default function CustomContainer({ children, ...rest }) {
  return <MainContainer {...rest}>{children}</MainContainer>;
}
